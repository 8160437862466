import NewspaperIcon from "@mui/icons-material/Newspaper";
import { Icon } from "@mui/material";
import { COLLABORATOR_ROLE } from "../constants/user-roles";
import { NewNewsletter, NewsletterDetails, Newsletters } from "../loadables";
import { Route } from "./routeInterface";

export const newslettersRoutes: Array<Route> = [
  {
    key: "newsletters-route",
    label: "Newsletters",
    path: "newsletters",
    enabled: true,
    component: Newsletters,
    isTopTab: true,
    order: 6,
    tabInfo: {
      id: "newsletters",
      startIcon: <Icon component={NewspaperIcon} fontSize="small" />,
      endIcon: null
    },
    leastRole: COLLABORATOR_ROLE,
  },
  {
    key: "new-newsletter-route",
    label: "New Newsletter",
    path: "newsletters/new",
    enabled: true,
    component: NewNewsletter,
    leastRole: COLLABORATOR_ROLE,
  },
  {
    key: "newsletter-details-route",
    label: "Newsletter details",
    path: "newsletters/:id",
    enabled: true,
    component: NewsletterDetails,
    leastRole: COLLABORATOR_ROLE,
  },
];
