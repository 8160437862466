import {
  ArrowCircleLeftRounded,
  ChevronLeft,
  ChevronRight,
  Close,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import {
  CSSObject,
  Theme,
  darken,
  styled,
  useTheme,
} from "@mui/material/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { UBICO_DOMAIN } from "../../../constants";
import { Account, UbicoSubscription } from "../../../constants/data-types";
import {
  NAVBAR_WIDTH_CLOSED,
  NAVBAR_WIDTH_OPENED,
} from "../../../constants/navbar/tabs";
import { MAX_TRIAL_CREDITS } from "../../../pages/settings/account/constants/account-billing";
import { RootState } from "../../../redux/store";
import { HOME_ROUTE, appRoutes } from "../../../routes/appRoutes";
import { Route } from "../../../routes/routeInterface";
import { isUserAllowed } from "../../../utils/user-role-utils";
import { UbicoBreadcrumbs } from "../../Breadcrumbs";
import UbicoNavButton from "../../custom/buttons/NavButton";
import ComingSoonFeatureChip from "../../custom/chips/ComingSoonFeatureChip";
import UbicoLinearProgress from "../../custom/progress.tsx/UbicoLinearProgress";
import NavbarLeftHeader from "./NavbarLeftHeader";

export const NAVBAR_LEFT_ELEVATION = 100;
const DRAWER_NAV_WIDTH = 400;

const openedNavMixin = (theme: Theme): CSSObject => ({
  width: NAVBAR_WIDTH_OPENED,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  zIndex: NAVBAR_LEFT_ELEVATION,
});

const closedNavMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: NAVBAR_WIDTH_CLOSED,
  zIndex: NAVBAR_LEFT_ELEVATION,
});

const openedSubNavMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_NAV_WIDTH,
  marginLeft: NAVBAR_WIDTH_OPENED,
  transition: theme.transitions.create("marginLeft", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedSubNavMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_NAV_WIDTH,
  marginLeft: NAVBAR_WIDTH_CLOSED,
  transition: theme.transitions.create("marginLeft", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

const NavbarDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedNavMixin(theme),
    "& .MuiDrawer-paper": openedNavMixin(theme),
  }),
  ...(!open && {
    ...closedNavMixin(theme),
    "& .MuiDrawer-paper": closedNavMixin(theme),
  }),
}));

const SubNavbarDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "navOpen",
})<{ navOpen?: boolean }>(({ theme, navOpen }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  zIndex: NAVBAR_LEFT_ELEVATION - 1,
  ...(navOpen && {
    ...openedSubNavMixin(theme),
    "& .MuiDrawer-paper": openedSubNavMixin(theme),
  }),
  ...(!navOpen && {
    ...closedSubNavMixin(theme),
    "& .MuiDrawer-paper": closedSubNavMixin(theme),
  }),
  marginLeft: NAVBAR_WIDTH_CLOSED,
}));

const ChevronIconButton = styled(IconButton)<{ open?: boolean }>(
  ({ theme, open }) => ({
    position: "fixed",
    top: theme.spacing(7),
    left: open ? theme.spacing(28) : theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: darken(theme.palette.primary.main, 0.15),
      color: darken(theme.palette.primary.contrastText, 0.15),
    },
    zIndex: NAVBAR_LEFT_ELEVATION + 2,
    transition: theme.transitions.create("left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
);

const AppContent = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  boxShadow: theme.shadows[2],
  minHeight: "100vh",
}));

interface NavbarLeftProps {
  children: any;
}

const NavbarLeft: React.FC<NavbarLeftProps> = ({
  children,
}): React.ReactElement => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  const [open, setOpen] = React.useState(true);
  const [selectedDrawerTab, setSelectedDrawerTab] =
    React.useState<Route | null>(null);
  const [selectedSubNav, setSelectedSubNav] =
    React.useState<Array<Route> | null>();
  const [selectedNav, setSelectedNav] = React.useState<Route | null>();

  const { user, user_role } = useSelector((state: RootState) => state.profile);
  const { is_logged_in } = useSelector((state: RootState) => state.auth);
  const subscription: UbicoSubscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );
  const account: Account = useSelector((state: RootState) => state.account);

  const NavBarTopItems = appRoutes.filter((route) => route.isTopTab);
  NavBarTopItems.sort((a, b) => a.order - b.order);

  const NavBarBottomItems = appRoutes.filter((route) => route.isBottomTab);
  NavBarBottomItems.sort((a, b) => a.order - b.order);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleTabSelected = (route: Route) => {
    // For drawer tabs
    if (route.isDrawer && !selectedDrawerTab) setSelectedDrawerTab(route);
    else setSelectedDrawerTab(null);

    if (route.expandOnOpen) setOpen(false)

    if (route.nestedRoutes && route.isNestedTabs) {
      // Selecting a nested tab
      setSelectedSubNav(route.nestedRoutes);
      setSelectedNav(route);
      navigate(`${route.path}/${route.nestedRoutes[0].path}`);
    } else {
      if (selectedNav && selectedSubNav)
        navigate(`${selectedNav.path}/${route.path}`);
      else navigate(route.path);
    }
  };

  function isSelectedTab(currRoute: Route): boolean {
    const currentPath = `/${currRoute.path}`;
    if (location.pathname.startsWith(currentPath)) return true;

    for (const route of appRoutes) {
      if (!route.nestedRoutes) continue;

      const nestedPath = `/${route.path}/${currRoute.path}`;
      if (location.pathname.startsWith(nestedPath)) return true;
    }

    return false;
  }

  React.useEffect(() => {
    const currentRoute = appRoutes.find(
      (route) => location.pathname === `/${route.path}`,
    );
    setSelectedSubNav(null);
    if (!currentRoute) {
      // find all nested routes and check if the current path is one of them
      // this is to handle the sidebar navigation when the user is on a nested route
      // Note that this currently supports only one level nested routes
      appRoutes.forEach((route) => {
        if (!route.nestedRoutes) return;
        route?.nestedRoutes?.map((nestedRoute) => {
          if (location.pathname.includes(`/${nestedRoute.path}`)) {
            setSelectedNav(route);
            setSelectedSubNav(route.nestedRoutes);
          }
        });
      });
    }
  }, [location]);

  const handleNavBack = () => {
    setSelectedSubNav(null);
    navigate(HOME_ROUTE);
  };

  const subNavGroups = Array.from(new Set(selectedSubNav?.map((n) => n.group)));
  const allowedNavs = selectedSubNav?.filter(
    (n) =>
      isUserAllowed(user_role, n.leastRole) &&
      !(n?.isInternalTesting && !user?.email?.includes(UBICO_DOMAIN)),
  );

  const isTrialCredits = subscription?.trial_credits_used < MAX_TRIAL_CREDITS;
  const trialCredits = subscription?.trial_credits_used;

  const usedCredits = isTrialCredits
    ? trialCredits
    : subscription?.credits_used;
  const totalCredits = isTrialCredits
    ? account?.plan_limits?.free_trial_credits
    : account?.plan_limits?.period_credits

  const creditsPercentage =
    Math.min((usedCredits / totalCredits) * 100, 100) || 0;

  return account?.completed_account_setup && is_logged_in ? (
    <Box sx={{ display: "flex" }} id="navbar-content">
      <ChevronIconButton onClick={handleDrawer} size="small" open={open}>
        {open ? (
          <ChevronLeft fontSize="small" />
        ) : (
          <ChevronRight fontSize="small" />
        )}
      </ChevronIconButton>
      <NavbarDrawer variant="permanent" open={open}>
        <NavbarLeftHeader open={open} />
        <Divider />
        <Box display="flex" justifyContent={"center"}>
          {allowedNavs ? (
            <Slide
              direction="right"
              in={allowedNavs !== null}
              mountOnEnter
              unmountOnExit
            >
              <List>
                <UbicoNavButton
                  startIcon={<ArrowCircleLeftRounded />}
                  navOpen={open}
                  selected={false}
                  onClick={handleNavBack}
                >
                  Back
                </UbicoNavButton>
                {subNavGroups.map((group, index) => {
                  const navs = allowedNavs?.filter((n) => n.group === group);
                  return (
                    <div key={index}>
                      {navs.length > 0 && open && (
                        <ListSubheader
                          sx={{
                            background: "transparent",
                            color: theme.palette.text.disabled,
                            paddingLeft: theme.spacing(3),
                          }}
                        >
                          <Typography variant="caption">{group}</Typography>
                        </ListSubheader>
                      )}
                      {navs.map((tab) => {
                        if (!tab.tabInfo) return;
                        return (
                          <ListItem
                            key={tab.key}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <UbicoNavButton
                              navOpen={open}
                              startIcon={tab.tabInfo.startIcon}
                              endIcon={tab.tabInfo.endIcon}
                              alwaysShowEndIcon={tab.tabInfo.alwaysShowEndIcon}
                              onClick={() => handleTabSelected(tab)}
                              selected={isSelectedTab(tab)}
                            >
                              {tab.label}
                            </UbicoNavButton>
                          </ListItem>
                        );
                      })}
                    </div>
                  );
                })}
              </List>
            </Slide>
          ) : (
            <>
              <List>
                {NavBarTopItems.map((tab) => {
                  let endIcon = tab.tabInfo.endIcon;
                  let disabled = false;
                  if (
                    (tab?.isInternalTesting && !user?.email?.includes(UBICO_DOMAIN)) ||
                    !tab?.enabled
                  ) {
                    disabled = true;
                    endIcon = <ComingSoonFeatureChip />;
                  }
                  if (!isUserAllowed(user_role, tab.leastRole)) return null;
                  return (
                    <ListItem
                      key={tab.key}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <UbicoNavButton
                        navOpen={open}
                        startIcon={tab.tabInfo.startIcon}
                        endIcon={endIcon}
                        alwaysShowEndIcon={tab.tabInfo.alwaysShowEndIcon}
                        onClick={() => handleTabSelected(tab)}
                        selected={isSelectedTab(tab)}
                        disabled={disabled}
                      >
                        {tab.label}
                      </UbicoNavButton>
                    </ListItem>
                  );
                })}
              </List>
              <List sx={{ position: "absolute", bottom: 0 }}>
                {open &&
                  account?.has_billing &&
                  subscription?.credits_used >= 0 &&
                  totalCredits >= 0 && (
                    <ListItemButton
                      key={"credits"}
                      sx={{
                        display: "block",
                        pl: theme.spacing(3),
                        pr: theme.spacing(3),
                        "&.MuiListItemButton-root:hover": {
                          bgcolor: "transparent",
                        },
                      }}
                      href="/settings/billing"
                      disableRipple
                      disableTouchRipple
                    >
                      <UbicoLinearProgress
                        variant="determinate"
                        value={creditsPercentage}
                        color={isTrialCredits ? "info" : "primary"}
                        startLabel={
                          isTrialCredits ? "Trial Credits Used" : "Credits Used"
                        }
                        endLabel={`${usedCredits || 0} / ${totalCredits}`}
                      />
                    </ListItemButton>
                  )}
                {NavBarBottomItems.map((tab) => {
                  if (
                    tab?.isInternalTesting &&
                    !user?.email?.includes(UBICO_DOMAIN)
                  )
                    return null;
                  if (!isUserAllowed(user_role, tab.leastRole)) return null;
                  return (
                    <ListItem
                      key={tab.key}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <UbicoNavButton
                        navOpen={open}
                        startIcon={tab.tabInfo.startIcon}
                        endIcon={tab.tabInfo.endIcon}
                        alwaysShowEndIcon={tab.tabInfo.alwaysShowEndIcon}
                        onClick={() =>
                          tab.onClick ? tab.onClick() : handleTabSelected(tab)
                        }
                        selected={
                          isSelectedTab(tab) ||
                          selectedDrawerTab?.key === tab.key
                        }
                      >
                        {tab.label}
                      </UbicoNavButton>
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
        </Box>
      </NavbarDrawer>
      <SubNavbarDrawer
        open={selectedDrawerTab !== null}
        navOpen={open}
        onClose={() => setSelectedDrawerTab(null)}
        elevation={15}
        hideBackdrop
        sx={{ zIndex: NAVBAR_LEFT_ELEVATION - 1 }}
      >
        <Box>
          <Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              p={theme.spacing(2)}
              pb={theme.spacing(1)}
              pt={theme.spacing(1)}
              alignItems={"center"}
            >
              <Typography variant="h6">{selectedDrawerTab?.label}</Typography>
              <IconButton onClick={() => setSelectedDrawerTab(null)}>
                <Close />
              </IconButton>
            </Stack>
            {selectedDrawerTab?.drawerComponent}
          </Stack>
        </Box>
      </SubNavbarDrawer>
      <AppContent
        open={open}
        sx={{ margin: 0, padding: theme.spacing(1) }}
        id="ubico-app-ready"
        className="ubico-app-ready"
      >
        {is_logged_in && account?.completed_account_setup && (
          <UbicoBreadcrumbs open={open} />
        )}
        <Box p={theme.spacing(3)} mt={theme.spacing(5)}>
          {children}
        </Box>
      </AppContent>
    </Box>
  ) : (
    children
  );
};

export default NavbarLeft;
